import React, { useEffect, useMemo, useState } from 'react';
import Dialog from 'material-ui/Dialog';
import CircularProgress from 'material-ui/CircularProgress';

import { TaoSubscriberDTO } from '@cvfm-front/tefps-types';
import { PatchObject } from 'api/commonTypes';
import { GenericFormV2 } from '@cvfm-front/commons-ui';
import { FormFieldTypeWithTitleV2 } from '@cvfm-front/commons-types';
import FlexCenter from 'commons/FlexCenter';
import useWatcher from 'commons/hooks/useWatcher';

import services from '../../../commons/services';
import { validateEmail, validateRequired } from '../../../commons/validatorsV2';

const { _t, _tg } = window.loadTranslations(__filename);

type UpdateTaoSubscriberProps = {
  updateSubscriber: (
    subscriberId: string,
    value: Array<PatchObject<unknown>>
  ) => Promise<void>;
  subscriber: TaoSubscriberDTO;
  closeModal: () => void;
};

const UpdateTaoSubscriber = ({
  updateSubscriber,
  subscriber,
  closeModal,
}: UpdateTaoSubscriberProps): JSX.Element => {
  const [patches, setPatches] = useState<Array<PatchObject<unknown>>>([]);

  const taoSubscriberUpdateService = useMemo(
    () => services.taoSubscriberUpdate,
    []
  );

  const watchedSubscriber = useWatcher(
    taoSubscriberUpdateService.watchUpdatedSubscriber,
    subscriber
  );

  const isUpdating = useWatcher(
    taoSubscriberUpdateService.watchIsUpdating,
    false
  );

  useEffect(() => {
    taoSubscriberUpdateService.setUpdatedSubscriber(subscriber);
  }, [subscriber]);

  const handleChange = (path: string, value: any) => {
    setPatches(prevPatches => {
      const newPatches = prevPatches.filter(p => p.path !== `/${path}`);
      return newPatches.concat({ op: 'replace', path: `/${path}`, value });
    });
  };

  const updateTaoSubscriber = async () => {
    if (patches.length > 0) {
      try {
        await updateSubscriber(subscriber.subscriberId, patches);
        closeModal();
      } catch (err) {
        closeModal();
      }
    }
  };

  const formFields: FormFieldTypeWithTitleV2[] = [
    {
      type: 'title',
      label: _t('title'),
    },
    {
      type: 'text',
      label: _tg('field.human.lastname_short'),
      value: watchedSubscriber.lastName,
      onValueChange: value => {
        handleChange('last-name', value);
        taoSubscriberUpdateService.setUpdatedSubscriber({
          ...watchedSubscriber,
          lastName: value,
        });
      },
      required: true,
      validator: validateRequired,
    },
    {
      type: 'text',
      label: _tg('field.human.firstname'),
      value: watchedSubscriber.firstName,
      onValueChange: value => {
        handleChange('first-name', value);
        taoSubscriberUpdateService.setUpdatedSubscriber({
          ...watchedSubscriber,
          firstName: value,
        });
      },
      required: true,
      validator: validateRequired,
    },
    {
      type: 'text',
      label: _tg('field.human.email'),
      value: watchedSubscriber.email,
      onValueChange: value => {
        handleChange('email', value);
        taoSubscriberUpdateService.setUpdatedSubscriber({
          ...watchedSubscriber,
          email: value,
        });
      },
      required: true,
      validator: validateEmail,
    },
  ];

  return (
    <Dialog modal open>
      {isUpdating && (
        <FlexCenter>
          <CircularProgress />
        </FlexCenter>
      )}
      <GenericFormV2
        fields={formFields}
        onSubmitCallback={updateTaoSubscriber}
        submitLabel={_tg('action.validate')}
        onCancelCallback={closeModal}
        cancelLabel={_tg('action.close')}
      />
    </Dialog>
  );
};

export default UpdateTaoSubscriber;
