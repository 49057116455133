import React, { useEffect } from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import { connect } from 'react-redux';

import { TaoProductSearchRequestDTO } from '@cvfm-front/tefps-types';
import { ListBody, ListBottom, ListWrapper } from 'commons/ListWrappers';
import useWatcher from 'commons/hooks/useWatcher';
import services from 'commons/services';
import BoButton from 'facade/BoButton';
import { getApiState, InternalApiState } from 'api/duck';

import { MarcheNavigation } from '../../MarcheNavigation';

import ProductTable from './ProductTable';
import ProductTableSideBar from './ProductTableSideBar';

const { _tg } = window.loadTranslations(__filename);

type ProductTablePageReduxProps = {
  canEdit: boolean;
};

const ProductTablePage = ({
  canEdit,
}: ProductTablePageReduxProps): JSX.Element => {
  const history = useHistory();
  const { url } = useRouteMatch();
  const request: Partial<TaoProductSearchRequestDTO> = useWatcher(
    services.taoProductSearchService.watchRequest,
    {}
  );

  const products = useWatcher(
    services.taoProductSearchService.watchProducts,
    []
  );

  const isLoading = useWatcher(
    services.taoProductSearchService.watchIsLoading,
    false
  );

  useEffect(() => {
    services.taoProductSearchService.getProducts();
  }, [request]);

  const goToNewProductPage = () => {
    const newProductId = uuidv4();
    history.push({
      pathname: `${url}/${newProductId}`,
      state: { isCreation: true },
    });
  };
  return (
    <div className="product-page_container">
      <ProductTableSideBar />
      <div className="product-page_content">
        <MarcheNavigation />
        <div className="product-page_table">
          <ListWrapper
            style={{
              padding: '0px 32px',
              margin: 0,
              maxWidth: '100%',
              maxHeight: '95%',
            }}
          >
            <ListBody loading={isLoading}>
              <ProductTable pagedProducts={products} />
            </ListBody>
            <ListBottom style={{ margin: '1% 3% 0%', display: 'flex' }}>
              {canEdit && (
                <BoButton
                  style={{ marginRight: 10 }}
                  label={_tg('action.create')}
                  onClick={goToNewProductPage}
                  primary
                />
              )}
            </ListBottom>
          </ListWrapper>
        </div>
      </div>
    </div>
  );
};

function mapStateToProps(state: InternalApiState): ProductTablePageReduxProps {
  const { userInfo } = getApiState(state);
  return {
    canEdit: !!userInfo && userInfo.rights.includes('TAO_PRODUCT_WRITE'),
  };
}

export default connect(mapStateToProps)(ProductTablePage);
