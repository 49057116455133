import {
  PatchObject,
  Setter,
  WatchFunctionType,
} from '@cvfm-front/commons-types';
import { Watcher } from '@cvfm-front/commons-utils';
import {
  ArticleConfigCreationDTO,
  ArticleConfigDTO,
} from '@cvfm-front/tefps-types';

import { apiGet, apiPatch, apiPut } from '../../../../api/helpers';

export type ArticleConfigFormRequest = Omit<
  Partial<ArticleConfigCreationDTO>,
  'pricePerUnit'
> & { pricePerUnit?: string };

export interface ArticleConfigApiServiceInterfaceFactory {
  (): ArticleConfigApiServiceInterface;
}

export interface ArticleConfigApiServiceInterface {
  watchFormRequest: WatchFunctionType<ArticleConfigFormRequest>;
  setFormRequest: Setter<ArticleConfigFormRequest>;
  createLinearArticleConfig: (
    articleConfigId: string
  ) => Promise<ArticleConfigDTO | undefined>;
  resetFormRequest: () => void;
  resetError: () => void;
  watchIsLoading: WatchFunctionType<boolean>;
  watchRequestError: WatchFunctionType<Error | undefined>;
  setRequestError: Setter<Error | undefined>;
  fetchArticleConfig: (articleConfigId: string) => void;
  updateArticleConfig: (
    articleConfigId: string,
    patches: Array<PatchObject<string | number>>
  ) => Promise<ArticleConfigDTO | undefined>;
}

const ArticleConfigApiService: ArticleConfigApiServiceInterfaceFactory = () => {
  const { setValue: setIsLoading, watchValue: watchIsLoading } = Watcher(false);

  const { setValue: setRequestError, watchValue: watchRequestError } = Watcher<
    Error | undefined
  >(undefined);

  const {
    watchValue: watchFormRequest,
    setValue: setFormRequest,
    getValue: getFormRequest,
  } = Watcher<ArticleConfigFormRequest>({});

  const buildRootApi = (articleConfigId: string): string => {
    if (!articleConfigId) {
      const e: Error = new Error(
        "Un UUID doit être utilisé comme identifiant pour créer la configuration d'article."
      );
      setRequestError(e);
      throw e;
    }
    return `/api/proxy/tao/api/v1/{cityId}/articleConfig/linearArticle/${articleConfigId}`;
  };

  const resetFormRequest: ArticleConfigApiServiceInterface['resetFormRequest'] = () => {
    setFormRequest({});
  };

  const resetError: ArticleConfigApiServiceInterface['resetError'] = () => {
    setRequestError(undefined);
  };

  const mapFormRequestToApiRequest = (
    formRequest: ArticleConfigFormRequest
  ): Partial<ArticleConfigCreationDTO> => {
    return {
      ...formRequest,
      pricePerUnit: Number(formRequest.pricePerUnit),
    };
  };

  const mapApiRequestToFormRequest = (
    apiRequest: ArticleConfigCreationDTO
  ): Partial<ArticleConfigFormRequest> => {
    return {
      ...apiRequest,
      pricePerUnit: apiRequest.pricePerUnit.toString(),
    };
  };

  const createLinearArticleConfig: ArticleConfigApiServiceInterface['createLinearArticleConfig'] = async (
    articleConfigId: string
  ) => {
    try {
      const rootApi = buildRootApi(articleConfigId);
      const formRequest = getFormRequest();
      const apiRequest = mapFormRequestToApiRequest(formRequest);
      setIsLoading(true);
      setRequestError(undefined);
      return await apiPut<ArticleConfigDTO>(rootApi, apiRequest);
    } catch (error) {
      setRequestError(error as Error);
      return undefined;
    } finally {
      setIsLoading(false);
    }
  };

  const fetchArticleConfig: ArticleConfigApiServiceInterface['fetchArticleConfig'] = (
    articleConfigId: string
  ) => {
    const rootApi = buildRootApi(articleConfigId);
    setIsLoading(true);
    setRequestError(undefined);
    apiGet<ArticleConfigDTO>(rootApi)
      .then(res => {
        const formRequest = mapApiRequestToFormRequest(res);
        setFormRequest(formRequest);
      })
      .catch(error => {
        setRequestError(error as Error);
      })
      .finally(() => setIsLoading(false));
  };

  const updateArticleConfig: ArticleConfigApiServiceInterface['updateArticleConfig'] = async (
    articleConfigId: string,
    patches: Array<PatchObject<string | number>>
  ) => {
    try {
      const rootApi = buildRootApi(articleConfigId);
      setIsLoading(true);
      setRequestError(undefined);
      return await apiPatch<ArticleConfigDTO>(rootApi, patches);
    } catch (error) {
      setRequestError(error as Error);
      return undefined;
    } finally {
      setIsLoading(false);
    }
  };

  return {
    watchFormRequest,
    setFormRequest,
    createLinearArticleConfig,
    resetFormRequest,
    resetError,
    watchIsLoading,
    watchRequestError,
    setRequestError,
    fetchArticleConfig,
    updateArticleConfig,
  };
};
export default ArticleConfigApiService;
