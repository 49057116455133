import React from 'react';

import { TaoSubscriberDTO } from '@cvfm-front/tefps-types';

import {
  DataBox,
  DataBoxContent,
  DataBoxHeader,
  DataBoxItem,
  DataBoxItemWrapper,
} from '../../../commons/DataBox';
import FakeInputBlock from '../../../commons/FakeInputBlock';
import CopyValueButton from '../../../commons/CopyValueButton';

const { _t, _tg } = window.loadTranslations(__filename);

type TaoSubscriberDetailSummaryProps = {
  subscriber: TaoSubscriberDTO;
};

const TaoSubscriberDetailSummary = ({
  subscriber,
}: TaoSubscriberDetailSummaryProps): JSX.Element => {
  return (
    <div className="order-detail-page_databox">
      <DataBox panel>
        <DataBoxHeader>
          <div className="order-detail-page_header_left">
            {_t('title.label')}
          </div>
          <div className="order-detail-page_header_right">
            {_t('title.id')}:
            <div className="order-detail-page_header_right-identifiant">
              {subscriber.subscriberId}
              <CopyValueButton
                value={subscriber.subscriberId}
                iconStyle={{ height: 15, color: 'white' }}
                spanStyle={{ marginLeft: 5 }}
              />
            </div>
          </div>
        </DataBoxHeader>
        <DataBoxContent>
          <div className="order-detail-page_item_wrapper">
            <DataBoxItemWrapper>
              <DataBoxItem style={{ maxWidth: '500px' }}>
                <FakeInputBlock
                  title={_tg('field.human.lastname_short')}
                  value={subscriber.lastName}
                />
                <FakeInputBlock
                  title={_tg('field.human.firstname')}
                  value={subscriber.firstName}
                />
                <FakeInputBlock
                  title={_tg('field.human.email')}
                  value={subscriber.email}
                />
              </DataBoxItem>
            </DataBoxItemWrapper>
          </div>
        </DataBoxContent>
      </DataBox>
    </div>
  );
};

export default TaoSubscriberDetailSummary;
