import { WatchFunctionType } from '@cvfm-front/commons-types';
import { Watcher } from '@cvfm-front/commons-utils';
import { TaoProductDTO } from '@cvfm-front/tefps-types';
import { apiGet } from 'api/helpers';

export interface TaoProductFetchServiceInterfaceFactory {
  (): TaoProductFetchServiceInterface;
}

export interface TaoProductFetchServiceInterface {
  getProduct: (productId: string) => Promise<TaoProductDTO | undefined>;
  watchIsLoading: WatchFunctionType<boolean>;
  watchHasLoadingError: WatchFunctionType<Error | undefined>;
}

const ROOT_API = '/api/proxy/tao/api/v1/{cityId}/taoProduct';

const TaoProductFetchService: TaoProductFetchServiceInterfaceFactory = () => {
  const { setValue: setIsLoading, watchValue: watchIsLoading } = Watcher(false);
  const {
    setValue: setHasLoadingError,
    watchValue: watchHasLoadingError,
  } = Watcher(undefined);

  const getProduct: TaoProductFetchServiceInterface['getProduct'] = async productId => {
    try {
      setIsLoading(true);
      setHasLoadingError(undefined);
      return await apiGet<TaoProductDTO>(`${ROOT_API}/${productId}`);
    } catch (error) {
      setHasLoadingError(error);
      return undefined;
    } finally {
      setIsLoading(false);
    }
  };
  return {
    getProduct,
    watchIsLoading,
    watchHasLoadingError,
  };
};

export default TaoProductFetchService;
