import * as React from 'react';
import { useRouteMatch } from 'react-router-dom';

import SimpleTable from 'commons/SimpleTable';
import 'tefps/Subscription/SubscriberTablePage.css';
import './ArticleTablePage.css';
import { ArticleConfigDTO } from '@cvfm-front/tefps-types';

const { _t } = window.loadTranslations(__filename);

type ArticleTableProps = {
  pagedArticles: Array<ArticleConfigDTO> | undefined;
};

const ArticleTable = ({
  pagedArticles: articles,
}: ArticleTableProps): JSX.Element => {
  const { url } = useRouteMatch();

  const getHeaderCols = () => [
    {
      label: _t('articleName'),
      width: 240,
      grow: 1,
    },
  ];

  const renderArticleNameCell = (article: ArticleConfigDTO): JSX.Element => {
    return (
      <a
        id={`${article.articleConfigId}-id`}
        className="article-table-row_id"
        href={`#${url}/${article.articleConfigId}`}
      >
        {article.name}
      </a>
    );
  };

  const renderRow = (article: ArticleConfigDTO) => {
    const row = [renderArticleNameCell(article)];
    return row;
  };

  return (
    <SimpleTable
      style={{ marginTop: 25 }}
      cols={getHeaderCols()}
      rowHeight={50}
      itemsRenderer={renderRow}
      items={articles ?? []}
    />
  );
};

export default ArticleTable;
