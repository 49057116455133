import { PatchObject, WatchFunctionType } from '@cvfm-front/commons-types';
import { Watcher } from '@cvfm-front/commons-utils';

import {
  ArticleConfigApiServiceInterface,
  ArticleConfigFormRequest,
} from './ArticleConfigApiService';

export interface ArticleConfigEditionServiceInterfaceFactory {
  (
    taoArticleConfigApiService: ArticleConfigApiServiceInterface
  ): ArticleConfigEditionServiceInterface;
}

export interface ArticleConfigEditionServiceInterface {
  onLoad: (isCreation: boolean | undefined, articleConfigId: string) => void;
  onReset: () => void;
  onCreate: (
    articleConfigId: string,
    handleReturn: () => void
  ) => Promise<void>;
  watchPatches: WatchFunctionType<Array<PatchObject<string | number>>>;
  onUpdateForm: (
    articleConfigId: string,
    handleReturn: () => void,
    patches: Array<PatchObject<string | number>>
  ) => Promise<void>;
  onValueChange: (
    isCreation: boolean | undefined,
    formRequest: Partial<ArticleConfigFormRequest>,
    key: string,
    v: string | number
  ) => void;
}

const ArticleConfigEditionService: ArticleConfigEditionServiceInterfaceFactory = (
  taoArticleConfigApiService: ArticleConfigApiServiceInterface
) => {
  const {
    watchValue: watchPatches,
    setValue: setPatches,
    getValue: getPatches,
  } = Watcher<Array<PatchObject<string | number>>>([]);
  const onLoad = (isCreation: boolean | undefined, articleConfigId: string) => {
    if (isCreation) {
      return;
    }
    taoArticleConfigApiService.fetchArticleConfig(articleConfigId);
  };

  const onReset = () => {
    taoArticleConfigApiService.resetFormRequest();
    taoArticleConfigApiService.resetError();
  };

  const onCreate = async (
    articleConfigId: string,
    handleReturn: () => void
  ) => {
    try {
      await taoArticleConfigApiService.createLinearArticleConfig(
        articleConfigId
      );
      handleReturn();
      taoArticleConfigApiService.resetFormRequest();
    } catch (e) {
      taoArticleConfigApiService.setRequestError(e as Error);
    }
  };

  const onUpdateForm = async (
    articleConfigId: string,
    handleReturn: () => void,
    patches: Array<PatchObject<string | number>>
  ) => {
    try {
      await taoArticleConfigApiService.updateArticleConfig(
        articleConfigId,
        patches
      );
      handleReturn();
      taoArticleConfigApiService.resetFormRequest();
    } catch (e) {
      taoArticleConfigApiService.setRequestError(e as Error);
    }
  };

  const updatePatch = (path: string, value: string | number) => {
    const prevPatches: Array<PatchObject<string | number>> = getPatches();
    const unchangedPatches = prevPatches.filter(p => p.path !== `/${path}`);
    const newPatches: Array<PatchObject<string | number>> = [
      ...unchangedPatches,
      {
        op: 'replace',
        path: `/${path}`,
        value,
      },
    ];
    setPatches(newPatches);
  };

  const onValueChange = (
    isCreation: boolean | undefined,
    formRequest: Partial<ArticleConfigFormRequest>,
    key: string,
    v: string | number
  ) => {
    taoArticleConfigApiService.setFormRequest({
      ...formRequest,
      [key]: v,
    });
    if (!isCreation) {
      updatePatch(key, v);
    }
  };

  return {
    onLoad,
    onReset,
    onCreate,
    watchPatches,
    onUpdateForm,
    onValueChange,
  };
};
export default ArticleConfigEditionService;
