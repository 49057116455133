import React, { useCallback, useEffect, useMemo } from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, useHistory, useLocation } from 'react-router-dom';
import { CircularProgress } from 'material-ui';

import { GenericFormV2 } from '@cvfm-front/commons-ui';
import {
  FormFieldTypeWithTitleV2,
  PatchObject,
} from '@cvfm-front/commons-types';

import services from '../../../../commons/services';
import useWatcher from '../../../../commons/hooks/useWatcher';
import { getApiState, InternalApiState } from '../../../../api/duck';
import { validateRequired } from '../../../../commons/validatorsV2';
import useSnackbar from '../../../../commons/CustomHooks/SnackBar/useSnackBar';

import { ArticleConfigFormRequest } from './ArticleConfigApiService';

const { _t, _tg } = window.loadTranslations(__filename);

type ArticleDetailPageReduxProps = {
  canEdit: boolean;
};

type ArticleDetailPageProps = RouteComponentProps<{
  articleConfigId: string;
}> &
  ArticleDetailPageReduxProps;

interface LocationState {
  isCreation?: boolean;
}

const ArticleDetailPage = ({
  canEdit,
  match,
}: ArticleDetailPageProps): JSX.Element => {
  const history = useHistory();
  const handleReturn = useCallback(() => {
    history.goBack();
  }, []);
  const { articleConfigId } = useMemo(() => match.params, [match]);
  const location = useLocation<LocationState>();
  const setMessage = useSnackbar();
  const isCreation = location.state?.isCreation;
  const {
    taoArticleConfigEditionService,
    taoArticleConfigApiService,
  } = services;

  const isLoading = useWatcher(
    taoArticleConfigApiService.watchIsLoading,
    false
  );
  const formRequest: Partial<ArticleConfigFormRequest> = useWatcher(
    taoArticleConfigApiService.watchFormRequest,
    {}
  );
  const requestError: Error | undefined = useWatcher(
    taoArticleConfigApiService.watchRequestError,
    undefined
  );
  const patches = useWatcher<Array<PatchObject<string | number>>>(
    taoArticleConfigEditionService.watchPatches,
    []
  );

  useEffect(() => {
    taoArticleConfigEditionService.onLoad(isCreation, articleConfigId);
  }, [articleConfigId]);

  const onCancel = useCallback(() => {
    taoArticleConfigEditionService.onReset();
    handleReturn();
  }, []);

  const onSubmit = useCallback(() => {
    if (isCreation) {
      taoArticleConfigEditionService
        .onCreate(articleConfigId, handleReturn)
        .then(() => {
          setMessage(_t('message.creationSuccess'));
        })
        .catch((e: Error) => taoArticleConfigApiService.setRequestError(e));
    } else {
      taoArticleConfigEditionService
        .onUpdateForm(articleConfigId, handleReturn, patches)
        .then(() => {
          setMessage(_t('message.updateSuccess'));
        })
        .catch((e: Error) => taoArticleConfigApiService.setRequestError(e));
    }
  }, [patches]);

  // On définit les formFields uniquement si les données à fetch ont déjà été fetch
  if (isLoading) {
    return <CircularProgress />;
  }

  const formFields: FormFieldTypeWithTitleV2[] = [
    {
      type: 'title',
      label: _t('section.title.articleDetails'),
    },
    {
      type: 'text',
      label: _t('field.name'),
      value: formRequest?.name,
      onValueChange: v => {
        taoArticleConfigEditionService.onValueChange(
          isCreation,
          formRequest,
          'name',
          v
        );
      },
      required: true,
      validator: validateRequired,
    },
    {
      type: 'text',
      label: _t('field.privateDescription'),
      value: formRequest?.privateDescription,
      onValueChange: v => {
        taoArticleConfigEditionService.onValueChange(
          isCreation,
          formRequest,
          'privateDescription',
          v
        );
      },
      required: true,
      validator: validateRequired,
    },
    {
      type: 'number',
      label: _t('field.pricePerUnit'),
      value: formRequest?.pricePerUnit?.toString(),
      onValueChange: v => {
        taoArticleConfigEditionService.onValueChange(
          isCreation,
          formRequest,
          'pricePerUnit',
          v
        );
      },
      required: true,
      validator: validateRequired,
    },
  ];

  return (
    <GenericFormV2
      fields={formFields}
      onSubmitCallback={onSubmit}
      onCancelCallback={onCancel}
      cancelLabel={_t('action.cancel')}
      submitLabel={isCreation ? _tg('action.create') : _t('action.confirm')}
      errorMessage={requestError?.message}
      disableOnlySubmit={!canEdit}
    />
  );
};

function mapStateToProps(state: InternalApiState): ArticleDetailPageReduxProps {
  const { userInfo } = getApiState(state);
  return {
    canEdit: !!userInfo && userInfo.rights.includes('TAO_ARTICLE_CONFIG_WRITE'),
  };
}

export default connect(mapStateToProps)(ArticleDetailPage);
