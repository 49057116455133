import { CircularProgress } from 'material-ui';
import React, { useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, useLocation } from 'react-router';

import {
  FormFieldTypeWithTitleV2,
  PatchObject,
} from '@cvfm-front/commons-types';
import { GenericFormV2, useWatcher } from '@cvfm-front/commons-ui';
import { TaoProductDTO } from '@cvfm-front/tefps-types';
import { getApiState, InternalApiState } from 'api/duck';
import useSnackbar from 'commons/CustomHooks/SnackBar/useSnackBar';
import FlexCenter from 'commons/FlexCenter';
import services from 'commons/services';

import { validateRequired } from '../../../../commons/validatorsV2';

const { _t, _tg } = window.loadTranslations(__filename);

type ProductDetailPageReduxProps = {
  canEdit: boolean;
};

type ProductDetailPageProps = RouteComponentProps<{
  productId: string;
}> &
  ProductDetailPageReduxProps;

interface LocationState {
  isCreation?: boolean;
}

const ProductDetail = ({
  match,
  canEdit,
}: ProductDetailPageProps): JSX.Element => {
  const setMessage = useSnackbar();
  const location = useLocation<LocationState>();
  const isCreation = location.state?.isCreation;

  const { productId } = useMemo(() => match.params, [match]);

  const taoProductCreateService = useMemo(
    () => services.taoProductCreateService,
    []
  );

  const taoProductFetchService = useMemo(
    () => services.taoProductFetchService,
    []
  );

  const taoProductUpdateService = useMemo(
    () => services.taoProductUpdateService,
    []
  );

  const [product, setProduct] = useState<TaoProductDTO>();
  const [patches, setPatches] = useState<Array<PatchObject<string>>>([]);

  const watchedProduct = useWatcher(
    taoProductUpdateService.watchUpdatedProduct,
    product
  );

  const isUpdating = useWatcher(taoProductUpdateService.watchIsUpdating, false);

  const isLoading = useWatcher(taoProductFetchService.watchIsLoading, false);

  const request = useWatcher(taoProductCreateService.watchRequest, {});

  const [formRequest, setFormRequest] = useState(() =>
    isCreation ? request : watchedProduct
  );

  const requestCreationError: Error | undefined = useWatcher(
    taoProductCreateService.watchCreationError,
    undefined
  );

  const requestUpdateError: Error | undefined = useWatcher(
    taoProductUpdateService.watchHasError,
    undefined
  );

  async function loadProduct() {
    if (productId && !isCreation) {
      const fetchedProduct = await taoProductFetchService.getProduct(productId);
      setProduct(fetchedProduct);
      setFormRequest(fetchedProduct);
    }
  }

  const handleChange = (path: string, value: string) => {
    setPatches(prevPatches => {
      const newPatches = prevPatches.filter(p => p.path !== `/${path}`);
      return newPatches.concat({ op: 'replace', path: `/${path}`, value });
    });
  };

  const updateProduct = async () => {
    if (patches.length > 0 && product) {
      await taoProductUpdateService.updateProduct(productId, patches);
      history.back();
      setMessage(_t('messages.successModification', { product: product.name }));
    }
  };

  useEffect(() => {
    if (!isCreation) {
      loadProduct();
    }
  }, [productId]);

  useEffect(() => {
    if (product) {
      taoProductUpdateService.setUpdatedProduct(product);
    }
  }, [product]);

  const handleFieldChange = (field: keyof TaoProductDTO, value: any) => {
    const updated = { ...formRequest, [field]: value };
    setFormRequest(updated);

    if (isCreation) {
      taoProductCreateService.setRequest(updated);
    } else {
      handleChange(field, value);
      taoProductUpdateService.setUpdatedProduct(updated);
    }
  };

  useEffect(() => {
    if (request && Object.keys(request).length > 0) {
      setFormRequest(request);
    }
  }, [request]);

  const formFields: FormFieldTypeWithTitleV2[] = [
    {
      type: 'title',
      label: _t('title'),
    },
    {
      type: 'text',
      label: _t('productName'),
      value: formRequest?.name,
      onValueChange: v => {
        handleFieldChange('name', v);
      },
      required: true,
      validator: validateRequired,
    },
    {
      type: 'text',
      label: _t('privateDescription'),
      value: formRequest?.privateDescription,
      onValueChange: v => {
        handleFieldChange('privateDescription', v);
      },
      required: true,
      validator: validateRequired,
    },
  ];

  const addNewProduct = async () => {
    const taoProduct = await taoProductCreateService.addProduct(productId);
    taoProductCreateService.resetRequest();
    history.back();
    if (taoProduct) {
      setMessage(_t('messages.successCreation', { product: taoProduct.name }));
    }
  };
  const cancelProduct = () => {
    if (!isCreation && product) {
      taoProductUpdateService.setUpdatedProduct({
        name: product.name,
        privateDescription: product.privateDescription,
      });
      setPatches([]);
      setFormRequest(product);
      taoProductUpdateService.resetError();
    } else {
      taoProductCreateService.resetRequest();
      taoProductCreateService.resetError();
      history.back();
    }
  };
  if (isLoading) {
    return <CircularProgress />;
  }
  return (
    <>
      {isUpdating && (
        <FlexCenter>
          <CircularProgress />
        </FlexCenter>
      )}
      <GenericFormV2
        fields={formFields}
        onSubmitCallback={!isCreation ? updateProduct : addNewProduct}
        onCancelCallback={cancelProduct}
        cancelLabel={_tg('action.cancel')}
        submitLabel={isCreation ? _tg('action.create') : _tg('action.save_1')}
        errorMessage={
          isCreation
            ? requestCreationError?.message
            : requestUpdateError?.message
        }
        disableOnlySubmit={!canEdit}
      />
    </>
  );
};

function mapStateToProps(state: InternalApiState): ProductDetailPageReduxProps {
  const { userInfo } = getApiState(state);
  return {
    canEdit: !!userInfo && userInfo.rights.includes('TAO_PRODUCT_WRITE'),
  };
}

export default connect(mapStateToProps)(ProductDetail);
