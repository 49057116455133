import { Setter, WatchFunctionType } from '@cvfm-front/commons-types';
import { Watcher } from '@cvfm-front/commons-utils';
import { TaoProductDTO } from '@cvfm-front/tefps-types';
import { PatchObject } from 'api/commonTypes';
import { apiPatch } from 'api/helpers';

export interface TaoProductUpdateServiceInterfaceFactory {
  (): TaoProductUpdateServiceInterface;
}

export interface TaoProductUpdateServiceInterface {
  updateProduct: (
    productId: string,
    patches: Array<PatchObject<unknown>>
  ) => Promise<any>;
  resetError: () => void;
  watchIsUpdating: WatchFunctionType<boolean>;
  watchHasError: WatchFunctionType<Error | undefined>;
  watchUpdatedProduct: WatchFunctionType<Partial<TaoProductDTO>>;
  setUpdatedProduct: Setter<Partial<TaoProductDTO>>;
}

const ROOT_API = '/api/proxy/tao/api/v1/{cityId}/taoProduct';

const TaoProductUpdateService: TaoProductUpdateServiceInterfaceFactory = () => {
  const { setValue: setIsUpdating, watchValue: watchIsUpdating } = Watcher(
    false
  );

  const { setValue: setHasError, watchValue: watchHasError } = Watcher(
    undefined
  );

  const {
    setValue: setUpdatedProduct,
    watchValue: watchUpdatedProduct,
  } = Watcher<Partial<TaoProductDTO>>({});

  const updateProduct: TaoProductUpdateServiceInterface['updateProduct'] = async (
    productId,
    patches
  ) => {
    try {
      setIsUpdating(true);
      setHasError(undefined);
      return apiPatch<TaoProductDTO>(`${ROOT_API}/${productId}`, patches);
    } catch (error) {
      setHasError(error);
      return undefined;
    } finally {
      setIsUpdating(false);
    }
  };

  const resetError: TaoProductUpdateServiceInterface['resetError'] = () => {
    setHasError(undefined);
  };

  return {
    updateProduct,
    resetError,
    watchIsUpdating,
    watchHasError,
    watchUpdatedProduct,
    setUpdatedProduct,
  };
};

export default TaoProductUpdateService;
