import { Setter, WatchFunctionType } from '@cvfm-front/commons-types';
import { Watcher } from '@cvfm-front/commons-utils';
import { TaoProductCreationDTO, TaoProductDTO } from '@cvfm-front/tefps-types';
import { apiPut } from 'api/helpers';

export interface TaoProductCreateServiceInterfaceFactory {
  (): TaoProductCreateServiceInterface;
}

export interface TaoProductCreateServiceInterface {
  addProduct: (productId: string) => Promise<TaoProductDTO | undefined>;
  resetRequest: () => void;
  resetError: () => void;
  watchCreationError: WatchFunctionType<Error | undefined>;
  watchIsUploading: WatchFunctionType<boolean>;
  watchRequest: WatchFunctionType<Partial<TaoProductCreationDTO>>;
  setRequest: Setter<Partial<TaoProductCreationDTO>>;
}

const ROOT_API = '/api/proxy/tao/api/v1/{cityId}/taoProduct';

const TaoProductCreateService: TaoProductCreateServiceInterfaceFactory = () => {
  const {
    watchValue: watchRequest,
    setValue: setRequest,
    getValue: getRequest,
  } = Watcher<Partial<TaoProductCreationDTO>>({});
  const {
    setValue: setCreationError,
    watchValue: watchCreationError,
  } = Watcher<Error | undefined>(undefined);

  const { setValue: setIsUploading, watchValue: watchIsUploading } = Watcher(
    false
  );

  const addProduct: TaoProductCreateServiceInterface['addProduct'] = async productId => {
    const request = getRequest();
    try {
      setCreationError(undefined);
      setIsUploading(true);
      return await apiPut<TaoProductDTO>(`${ROOT_API}/${productId}`, request);
    } catch (e) {
      setCreationError(e);
      return undefined;
    } finally {
      setIsUploading(false);
    }
  };

  const resetRequest: TaoProductCreateServiceInterface['resetRequest'] = () => {
    setRequest({});
  };

  const resetError: TaoProductCreateServiceInterface['resetError'] = () => {
    setCreationError(undefined);
  };

  return {
    addProduct,
    resetRequest,
    resetError,
    watchCreationError,
    watchIsUploading,
    watchRequest,
    setRequest,
  };
};

export default TaoProductCreateService;
