import React, { useCallback, useEffect } from 'react';
import { connect } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';
import { useHistory, useRouteMatch } from 'react-router-dom';

import { ListBody, ListBottom, ListWrapper } from 'commons/ListWrappers';
import useWatcher from 'commons/hooks/useWatcher';
import services from 'commons/services';
import { TaoArticleSearchRequestDTO } from '@cvfm-front/tefps-types';

import { MarcheNavigation } from '../../MarcheNavigation';
import BoButton from '../../../../facade/BoButton';
import { getApiState, InternalApiState } from '../../../../api/duck';

import ArticleTable from './ArticleTable';
import ArticleTableSidebar from './ArticleTableSidebar';

const { _tg } = window.loadTranslations(__filename);

type ArticleTablePageReduxProps = {
  canEdit: boolean;
};

const ArticleTablePage = ({
  canEdit,
}: ArticleTablePageReduxProps): JSX.Element => {
  const { taoArticleSearchService } = services;
  const { url } = useRouteMatch();
  const history = useHistory();
  const request: Partial<TaoArticleSearchRequestDTO> = useWatcher(
    taoArticleSearchService.watchRequest,
    {}
  );
  const articles = useWatcher(taoArticleSearchService.watchArticles, []);
  const isLoading = useWatcher(taoArticleSearchService.watchIsLoading, false);
  useEffect(() => {
    taoArticleSearchService.getArticles();
  }, [request]);

  const onCreate = useCallback(() => {
    const articleConfigId = uuidv4();
    history.push({
      pathname: `${url}/${articleConfigId}`,
      state: { isCreation: true },
    });
  }, []);
  return (
    <div className="article-page_container">
      <ArticleTableSidebar />
      <div className="article-page_content">
        <MarcheNavigation />
        <div className="article-page_table">
          <ListWrapper
            style={{
              padding: '0px 32px',
              margin: 0,
              maxWidth: '100%',
              maxHeight: '95%',
            }}
          >
            <ListBody loading={isLoading}>
              <ArticleTable pagedArticles={articles} />
            </ListBody>
            <ListBottom style={{ margin: '1% 3% 0%', display: 'flex' }}>
              {canEdit && (
                <BoButton
                  label={_tg('action.create')}
                  onClick={onCreate}
                  primary
                  style={{ marginRight: 10 }}
                />
              )}
            </ListBottom>
          </ListWrapper>
        </div>
      </div>
    </div>
  );
};

function mapStateToProps(state: InternalApiState): ArticleTablePageReduxProps {
  const { userInfo } = getApiState(state);
  return {
    canEdit: !!userInfo && userInfo.rights.includes('TAO_ARTICLE_CONFIG_WRITE'),
  };
}

export default connect(mapStateToProps)(ArticleTablePage);
